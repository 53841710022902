:root {
  --transport-height: 2rem;
  --button: var(--transport-height);
}

html, body, #root {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif;
}

body, #root {
  height: 100vh;
  width: 100vw;
}

.cover-image {
  width: 15em;
  height: 15em;
}

.main {
  height: 100vh;
  width: 100vw;
  background-color: #fffff9;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.pad {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #fffff9;
  z-index: 10;
  position: sticky;
  transition: 500ms all ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pad.hidden {
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.hide-pad {
  display: none;
  position: absolute;
  background-color: black;
  padding: 2em;
}

.pad-display {
  width: 30em;
  height: 30em;
  background-color: #fffff9; 
  display: flex;
}

@media (max-width: 30em) {
  .pad-display {
    width: 20em;
    height: 20em;
    background-color: pink; 
    display: flex;
  }
}

.current-track-name {
  padding: 0.5em;
  padding-right: 1em;
}

.current-track-name h1 {
  padding: 0;
  margin: 0;
  display: inline;
}

.track-list {
  max-width: 63em;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 63em) {
  .track-list {
    grid-template-columns: repeat(2, 1fr);
    max-width: 47em;
  }
}

@media (max-width: 47em) {
  .track-list {
    grid-template-columns: repeat(2, 1fr);
    max-width: 31em;
  }
}

@media (max-width: 31em) {
  .track-list {
    grid-template-columns: repeat(1, 1fr);
    max-width: 15em;
  }
}

.track {
  background-color: white;
  height: 15em;
  width: 15em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.transport {
  background-color: white;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: all 0.2s linear;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transport.hidden {
  transform: translateY(calc(2 * var(--transport-height))); 
}

.controls {
  width: var(--transport-height);
  padding: 1em;
  height: var(--transport-height);
}

.transport.closed {
  transform: translateY(var(--transport-height));
}

.open-pad {
  font-size: 2em;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: #ffffff00;
  width: 20em;
  cursor: pointer;
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}

@media (max-width: 50em) {
  .open-pad {
    width: 0em;
    flex-grow: 1;
  }
}

@-webkit-keyframes mover {
    0% { transform: translateY(2px); }
    100% { transform: translateY(-7px); }
}
@keyframes mover {
    0% { transform: translateY(2px); }
    100% { transform: translateY(-7px); }
}

.play {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: var(--button);
  background-color: transparent;
  
  border-color: transparent transparent transparent #202020;
  transition: 100ms all ease;
  will-change: border-width;
  cursor: pointer;

  border-style: double;
  border-width: 0px 0 0px calc(var(--button) * 0.9);
}

.play.paused {
  border-style: solid;
  border-width: calc(var(--button) / 2) 0 calc(var(--button) / 2) calc(var(--button) * 0.9);
}

.track .play.playing {
  border-color: transparent transparent transparent #202020;
}

.play:hover {
  border-color: transparent transparent transparent #404040;
}

.transport .play {
  position: absolute;
}

.track .play {
  position: relative;
  top: 14em;
  left: 14em;
  border-color: transparent transparent transparent transparent;
}

.track:hover .play {
  border-color: transparent transparent transparent #202020;
}

@media (max-width: 31em) {
  .play {
    border-color: transparent transparent transparent #202020 !important;
  }
}
